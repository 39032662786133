import React from 'react';
import Chart from 'react-google-charts';
import Header from './Header';
import Footer from './Footer';

const IPR = () => {
  const PatentData = [
    { name: '2018', filled: 15, published: 10, granted: 2 },
    { name: '2019', filled: 20, published: 18, granted: 2 },
    { name: '2020', filled: 11, published: 9, granted: 2 },
    { name: '2021', filled: 30, published: 11, granted: 5 },
    { name: '2022', filled: 20, published: 9, granted: 8 },
  ];

  const chartData = [
    ['Year', 'Filled', 'Published', 'Granted'],
    ...PatentData.map(({ name, filled, published, granted }) => [name, filled, published, granted]),
  ];

  const options = {
    title: 'Patent Data',
    hAxis: { title: 'Year' },
    vAxis: { title: 'Total Patents' },
    legend: { position: 'top' },
  };
  return (
    <>
      <Header />
      <div className="Heading">
        <div className="HeadingText">INTELLECTUAL PROPERTY RIGHTS CELL</div>
      </div>
      <div className="Content">
        <div className="ContentStarter">
          National Institute of Technology, Tiruchirappalli (NIT Trichy) is dedicated to research,
          teaching, and extension of knowledge to the public. One of the missions of the NIT Trichy
          is to develop the human intellectual capability to its fullest potential. Inherent in this
          responsibility is the need to encourage the production of creative and scholarly works and
          the development of new and useful materials, devices, processes, and other intellectual
          property with commercial value. These activities contribute to the professional
          development of the individuals involved, enhance the reputation of NIT Trichy, provide
          additional educational opportunities to students, and promote public welfare. In addition
          to new machines, compositions of matter, and written materials, which traditionally have
          been the subject of patents and copyrights, computer software, video courses, etc. are now
          normal outcomes of NIT Trichy activities
        </div>
        <div className="ContentStarter">
          The pace of modern science, resulting in new and useful inventions, initiated a need for a
          central policy in determining the course of the creation, protection, and
          commercialization of intellectual property at NIT Trichy. This has resulted in the
          establishment of the Intellectual Property Right (IPR) Cell and the Intellectual Property
          Policy to encourage the creation and protection of intellectual property in the Institute
          in the year 2017.
        </div>
      </div>
      <div className="Infrastructure">
        <div className="CenterHeading">OBJECTIVES OF THE NIT TRICHY - IP POLICY:</div>
        <div className="InfrastructurePointsBullet">
          <ul>
            <li>
              To impart knowledge about intellectual property rights to faculty, staff, scholars,
              and students.
            </li>
            <li>
              To lay down a transparent administrative system for assessing the ownership and
              assignment of intellectual property.
            </li>
            <li>
              Facilitate the faculty members, staff members, research scholars, and students to
              obtain IP.
            </li>
            <li>To commercialize the IP owned by the institute.</li>
            <li>
              Sharing of the revenue generated by the intellectual property generated and owned by
              the institute.
            </li>
          </ul>
        </div>
      </div>
      <div className="Infrastructure">
        <div className="CenterHeading">NOTABLE IPR CELL ACTIVITIES</div>
        <div className="InfrastructurePointsBullet">
          <ul>
            <li>NIT Trichy-IPR was released in the year 2017 with the approval of BoG.</li>
            <li>Empanelled law firms to carry out patent filing activities.</li>
            <li>
              Many workshops have been conducted to create IPR awareness among the faculty, research
              scholars, and students.
            </li>
            <li>
              100 patent applications have been filed through IPR Cell by the faculty and research
              scholars.
            </li>
            <li>49 patents have been granted.</li>
            <li>Successfully trademarked Institute logos.</li>
          </ul>
        </div>
      </div>
      <div className="Infrastructure">
        <div className="CenterHeading">THE HIGHLIGHTS OF NIT TRICHY IPR POLICY</div>
        <div className="InfrastructurePointsBullet">
          <ul>
            <li>
              <strong>IP OWNERSHIP:</strong> Intellectual property of any kind created by faculty,
              students, staff, project staff, visitors, and others, such as trainees from other
              institutes, participating in NIT Trichy programs or using NIT Trichy funds or
              facilities, are owned by the institute.
            </li>
            <li>
              <strong>IP MANAGEMENT PERSONNEL:</strong> Mode of appointment of IP Management
              Personnel and Intellectual Property Committee (IPC) to help administer intellectual
              property and to make suitable recommendations for implementation.
            </li>
            <li>
              <strong>EVALUATION AND FLOW DIAGRAM:</strong> A procedure for the techno-commercial
              evaluation of invention and patent invention to grant flow diagram is given.
            </li>
            <li>
              <strong>REVENUE SHARING:</strong> 70% of the net earnings from the commercialization
              of IP would be distributed to the inventors, and the remaining 30% would be shared by
              NIT Trichy.
            </li>
          </ul>
        </div>
      </div>
      <div className="ResearchPlot">
        <div className="ResearchPlotHeading">Patents Filled, Published & Granted</div>
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={chartData}
          options={options}
        />
      </div>
      <div className="ContactInformation">
        <div className="ContactInfoContent">HEAD IPR CELL: Dr. M. Premalathar</div>
        <div className="ContactInfoContent"> Professor, Department of Energy and Environment.</div>
        <div className="ContactInfoContent">
          {' '}
          National Institute of Technology Tiruchirappalli - 620015 Tamil Nadu, India
        </div>
      </div>

      <Footer />
    </>
  );
};

export default IPR;
