import React from 'react';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './CardCarousel.css'; // Add your CSS file for additional styling

const Carousel = () => {
  const slides = [
    {
      heading:
        'NIT-T Fosters Young Innovators: Transforming Ideas into Real-World Prototypes',
      text: `The National Institute of Technology — Tiruchi (NIT-T), in partnership with the National Innovation Foundation India (NIF) and the Department of Science and Technology, Delhi, recently mentored young inventors aged 10-15. Thirteen schoolchildren from Tamil Nadu and Puducherry presented innovative prototypes, including an adjustable upper berth for senior citizens, a chaff separator, and an LPG cylinder trolley. Part of the INSPIRE-MANAK scheme, the workshop featured talks from senior NIT-T professors on innovation, AI, intellectual property, and rapid prototyping. Participants also explored 3-D printing techniques, guided by NIT-T Director G. Aghila.`,
      imageUrl: './NIF Workshop.jpeg',
      link: 'https://www.thehindu.com/news/cities/Tiruchirapalli/nit-t-hosts-mentorship-workshop-for-schoolchildren/article68384817.ece',
    },
    {
      heading:
        'Empowering Futures: 230 Students Graduate from NIT-Trichys Skill Hub Centre',
      text: `Around 230 students who completed skill-based courses at the Skill Hub Centre in the National Institute of Technology, Trichy, received their course completion certificates. This initiative especially benefited unemployed individuals and school dropouts aged 15 to 45. Trainers were also recognized for their contributions. The centre plans to introduce new programs in microfinance, VLSI design, and solar PV installation.`,
      imageUrl: './Skill Hub 1.jpg',
      link: 'https://timesofindia.indiatimes.com/city/trichy/230-students-graduate-from-nit-trichys-skill-centre/articleshow/110923276.cms',
    },
    {
      heading: 'NIT-Trichy, IIT Kanpur, and IISc Bangalore has signed an MoA to establish Centre for Condition Assessment and Management Plan (CAMP) for Cauvery river basin',

      text: `On February 28th, 2024, in New Delhi, a Memorandum of Agreement was signed between the Department of Water Resources, River Development and Ganga, Ministry of Jal Shakti, Government of India, IIT Kanpur, and a Consortium of Institutes comprising the Indian Institute of Science, Bangalore, and the National Institute of Technology, Tiruchirappalli. The objective of this agreement is to establish Centers for Cauvery River Basin Management Studies in the states of Karnataka and Tamil Nadu. The mandate of these centers is to prepare the Cauvery River Basin Condition Assessment & Management Plan. `,
      imageUrl: './camp.JPG',
      link: 'https://pib.gov.in/PressReleasePage.aspx?PRID=2016535#:~:text=On%20February%2028%2C%202024%20in,%2C%20River%20Development%20%26%20Ganga%20Rejuvenation%2C',
    },
    {
      heading: 'NIT-Trichy signs MoU with Prince of Songkla University',

      text: `NITT has signed an MOU with PSU, where PSU's President, Assistant Professor Dr. Niwat Keawpradub, represented PSU in signing the agreement at the PSU President's Office in Thailand. The MOU encompasses Hat Yai, Pattani, Phuket, Trang, and Surat Thani PSU campuses. This initiative aims to enhance the research potential and educational capabilities of the country. The focus of the MOU includes the development of research across various clusters such as Energy, Food, Water, Climate Change, Materials Science, Health, and ASEAN Studies. Under this agreement, professors, researchers, and students will have access to high-quality research tools, opportunities to exchange experiences and research management methodologies, and collaborate on joint research projects.`,
      imageUrl: './Songkla.jpg',
      link: '/',
    },
    {
      heading: 'NIT-Trichy inks MoU with Datanetiix',

      text: `The National Institute of Technology, Trichy (NIT-T), recently finalized a significant Memorandum of Understanding (MoU) with Datanetiix Solutions India Pvt Ltd. This collaborative partnership aims to foster innovation and facilitate knowledge exchange between academia and industry. It holds the potential to pave the way for groundbreaking advancements in technology and research initiatives within the region.`,
      imageUrl: './DTNIX.jpeg',
      link: 'https://timesofindia.indiatimes.com/city/trichy/nit-trichy-inks-mou-with-datanetiix-solutions/articleshow/101972581.cms',
    },
    {
      heading: 'NIT Trichy organises Workshop on Land cover, Climate Change, and Water Resources',
      text: `The National Institute of Technology, Trichy (NIT Trichy), has initiated efforts to tackle some of the most urgent environmental challenges by launching a five-day Global Initiative for Academic Networks (GIAN) workshop. The workshop, entitled 'Assessing the Impact of Land Cover and Climate Change on Water Resources Using the Soil Water Assessment Tool (SWAT),' was inaugurated at the Seminar Hall of the Department of Civil Engineering, NIT Trichy, on September 25, 2023.`,
      imageUrl: './GlobalWorkshop.jpg',
      link: 'https://timesofindia.indiatimes.com/city/trichy/workshop-on-climate-change-impact-on-water-resources-modelling/articleshow/106108449.cms',
    },

    {
      heading: 'NIT-T, Illinois Tech to offer joint degree in Data Science, AI',
      text: `The National Institute of Technology-Tiruchi (NIT-T) signed a Memorandum of Understanding (MoU) with the Illinois Institute of Technology (Illinois Tech). This agreement allows both institutions to jointly offer a degree program in Data Science and Artificial Intelligence starting in Fall 2024.`,
      imageUrl: './illonis.jpeg',
      link: 'https://www.thehindu.com/news/cities/Tiruchirapalli/nit-t-illinois-tech-to-offer-joint-degree-in-data-science-ai/article67554328.ece',
    },
    {
      heading: 'NIT Trichy and Armored Vehicles Nigam Ltd(AVNL) signs MoU',
      text: `NITT and AVNL/AVANI intend to collaborate or continue collaborating in various areas, including research, consultancy, advisory services, education, training, and projects under Corporate Social Responsibility (CSR).`,
      imageUrl: './AVNL.jpeg',
      link: 'https://timesofindia.indiatimes.com/city/trichy/nit-t-signs-mou-with-avnl-for-defence-research/articleshow/99199439.cms',
    },
    {
      heading: 'NIT-T and ISRO team review technology development projects',
      text: `
      A joint management committee comprising representatives from the National Institute of Technology — Tiruchi (NIT-T) and the Indian Space Research Organisation (ISRO) recently conducted a review of the technology development projects undertaken by the Space Technology Incubation Centre (STIC). This center was established at the NITT campus as part of the ongoing collaboration between the two institutions.`,
      imageUrl: './ISRO2023.jpeg',
      link: 'https://www.thehindu.com/news/cities/Tiruchirapalli/nit-t-and-isro-team-review-technology-development-projects/article67259695.ece',
    },
    {
      heading: 'NIT Trichy hosts Perkin Elmer programme',
      text: `NIT Tiruchirappalli organized a one-day program titled ‘PerkinElmer Academia Research Forum 2023’ in collaboration with PerkinElmer India. The event centered around a Knowledge Conclave discussing current research trends and pertinent solutions within the Research Domain and Equipment Health Inspection. This program was held as part of the Swacchatha Special Campaign 3.0 and the Diamond Jubilee Celebration.`,
      imageUrl: './Perkin.jpeg',
      link: 'https://pib.gov.in/PressReleasePage.aspx?PRID=1963872',
    },
    {
      heading: 'DCO, NIT-T ink MoU on census data analysis workstation',
      text: `The Directorate of Census Operations (DCO) Tamil Nadu signed a Memorandum of Understanding (MoU) with the National Institute of Technology – Tiruchi (NIT-T) to establish a workstation dedicated to promoting research on census data.`,
      imageUrl: './DCO.jpeg',
      link: 'https://www.thehindu.com/news/cities/Tiruchirapalli/dco-nit-t-ink-mou-on-census-data-analysis-workstation/article66838534.ece',
    },
    {
      heading:
        'Genesis’23: NIT Tiruchirappalli Institute Innovation Council Inspires Innovation and Entrepreneurship',
      text: `
      Genesis'23, the flagship event of the Institute Innovation Council of the National Institute of Technology Tiruchirappalli (IIC-NITT), held on September 20th, 2023, showcased the institute's commitment to fostering entrepreneurship among its talented student community.`,
      imageUrl: './Genesis_1.JPG',
      link: 'https://pib.gov.in/PressReleseDetail.aspx?PRID=1959764',
    },
    // NITT Trichy and Armored Vehicles Nigam Ltd(AVNL) signs MoU
  ];

  const responsive = {
    0: { items: 1 },
    600: { items: 1 },
    1024: { items: 1 },
  };

  return (
    <AliceCarousel
      autoPlay
      autoPlayInterval={7000}
      responsive={responsive}
      buttonsDisabled={true} // Disable default buttons
      dotsDisabled={false}
      infinite={true}
    >
      {slides.map((slide, index) => (
        <div key={index} className="CarouselItem">
          <div className="Carousel">
            <div className="CarouselContent">
              <div className="CarouselHeading">{slide.heading}</div>
              <div className="CarouselText">{slide.text}</div>
              <Link to={slide.link} className="CarouselButton" target="_blank">
                <div className="CarouselButtonContent">KNOW MORE</div>
              </Link>
            </div>
            <div className="CarouselBackground">
              <img src={slide.imageUrl} alt="" className="CarouselPicture" />
            </div>
          </div>
        </div>
      ))}
    </AliceCarousel>
  );
};

export default Carousel;
