import React from 'react';
import Banner from './Banner';
import Navigation from './Navigation';
import Footer from './Footer';
import Header from './Header';
import data from './Proposals.json';

const ProposalsScheme = () => {

    const proposalsRows = data.map((proposal) => (
        <tr key={proposal.Sno}>
          <td>{proposal.Sno}</td>
          <td className="TitleProjects">{proposal.Scheme}</td>
          <td>{proposal.Agency}</td>
          <td>{proposal.To}</td>
          <td className="TitleProjects"><a href={proposal.Link} target="_blank" rel="noopener noreferrer">{proposal.Link}</a></td>
        </tr>
      ));
      
  return (
    <>
      <Header />
      <div className="Projects">
      <div className="ProjectsHeading">Call for Proposals</div>
        <div className="ProjectsTable">
          <table className="ProjectTable">
            <thead className="ProjectTableHead">
              <tr className="ProjectTableRow">
                <th>S.No</th>
                <th>Scheme Name</th>
                <th>Agency</th>
                <th>Deadline</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>{proposalsRows}</tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProposalsScheme;
